import Lenis from '@studio-freight/lenis'
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import CSSPlugin from 'gsap/CSSPlugin';
gsap.registerPlugin(ScrollTrigger, CSSPlugin);
import Swiper from 'swiper';
import { EffectFade } from 'swiper/modules';
Swiper.use([EffectFade]);

const setScrollbarWidth = () => {
	const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
	// カスタムプロパティの値を更新する
	document.documentElement.style.setProperty('--scrollbar', `${scrollbarWidth}px`);
};

setScrollbarWidth();

window.addEventListener("pageshow", function () {
	const lenis = new Lenis({
		lerp: 0.1,
		duration: 1.7,
		easing: function (t) { return (t === 1 ? 1 : 1 - Math.pow(2, -10 * t)) },
		smooth: true,
		smoothTouch: false,
	});

	function raf(time) {
		lenis.raf(time);
		ScrollTrigger.update();
		requestAnimationFrame(raf);
	}

	requestAnimationFrame(raf);

	//lenis.scrollTo("html", 'duration:0');

	//console.log(lenis);

	const body = document.querySelector('body');
	const btns = document.querySelectorAll('.js-header__btn');
	const menu_targets = document.querySelectorAll('.js-megamenu');
	const menu_overlay = document.querySelector('.overlay');

	//let state = false;
	btns.forEach((btn, index) => {
		btn.addEventListener("click", function () {
			let nextDom = this.nextElementSibling;

			if (this.getAttribute('aria-expanded') == 'false') {
				//
				btns.forEach((btn, index) => {
					btn.setAttribute('aria-expanded', 'false');
				});
				this.setAttribute('aria-expanded', 'true');
				//
				menu_targets.forEach((menu_target, index) => {
					menu_target.setAttribute('aria-hidden', 'true');
				});
				nextDom.setAttribute('aria-hidden', 'false');

				//
				menu_overlay.classList.add('open');

				//
				//body.classList.add('fixed');
				lenis.stop();
			} else {

				//
				this.setAttribute('aria-expanded', 'false');
				nextDom.setAttribute('aria-hidden', 'true');
				//
				menu_overlay.classList.remove('open');
				body.classList.remove('fixed');
				lenis.start();
			}
		});
	});
	menu_overlay.addEventListener("click", function () {
		btns.forEach((btn, index) => {
			btn.setAttribute('aria-expanded', 'false');
		});
		menu_targets.forEach((menu_target, index) => {
			menu_target.setAttribute('aria-hidden', 'true');
		});
		menu_overlay.classList.remove('open');
		lenis.start();
	});






	const spBtns = document.querySelectorAll('.hbMenu .navigation__title');
	const spTargets = document.querySelectorAll('.hbMenu .navigation__item__list');

	spBtns.forEach((spBtn) => {
		spBtn.addEventListener("click", function (e) {
			e.preventDefault();

			let nextDom = this.nextElementSibling;

			if (this.getAttribute('aria-expanded') == 'false') {
				this.setAttribute('aria-expanded', 'true');
				nextDom.setAttribute('aria-hidden', 'false');
			} else {

				//
				this.setAttribute('aria-expanded', 'false');
				nextDom.setAttribute('aria-hidden', 'true');
			}
		});
	});




	if (document.querySelector('#hbMenu__lang__btn')) {
		const hlbtn = document.querySelector('#hbMenu__lang__btn');
		const hlmenu_target = document.querySelector('#hbMenu__lang__body');

		//let state = false;
		hlbtn.addEventListener("click", function () {
			if (this.getAttribute('aria-expanded') == 'false') {
				//
				this.setAttribute('aria-expanded', 'true');
				hlmenu_target.setAttribute('aria-hidden', 'false');
			} else {

				//
				this.setAttribute('aria-expanded', 'false');
				hlmenu_target.setAttribute('aria-hidden', 'true');
			}
		});
	}

	const anchorLinks = document.querySelectorAll('a[href^="#"]');
	const anchorLinksArr = Array.prototype.slice.call(anchorLinks);
	const header = document.querySelector('.header');
	const anchorHash = location.hash;

	if (anchorHash) {
		window.scrollTo({
			top: 0,
			behavior: "instant"
		});
		const targetElement = document.querySelector(anchorHash);
		const targetOffsetTop = window.pageYOffset + targetElement.getBoundingClientRect().top;
		const headerHeight = header.offsetHeight * 1.5;
		const totalScrollAmount = targetOffsetTop - headerHeight;
		//const totalScrollAmount = targetOffsetTop - 40;

		var fn = function () {
			window.scrollTo({
				top: totalScrollAmount,
				behavior: "smooth"
			});
		};
		var tm = 500;
		setTimeout(fn, tm);
	}


	if (document.querySelectorAll('.js--popup')[0]) {
		const modalBtns = document.querySelectorAll('.js--popup');
		const modal_targets = document.querySelectorAll('.js--popbody');
		const modal_close = document.querySelectorAll('.js--popclose');
		const modal_overlay = document.querySelector('.popbody__overlay');
		const modal_body = document.querySelector('.popbody');
		const modal_inner = document.querySelector('.popbody__inner');

		const modalClose = function () {
			modal_inner.scrollTop = 0;
			modalBtns.forEach((btn, index) => {
				btn.setAttribute('aria-expanded', 'false');
			});
			modal_targets.forEach((menu_target, index) => {
				menu_target.setAttribute('aria-hidden', 'true');
			});
			modal_body.classList.remove('open');
			lenis.start();



		}

		//let state = false;
		modalBtns.forEach((modalBtn, index) => {
			modalBtn.addEventListener("click", function (e) {
				e.preventDefault();

				if (this.getAttribute('aria-expanded') == 'false') {
					//
					modalBtns.forEach((btn, index) => {
						btn.setAttribute('aria-expanded', 'false');
					});
					this.setAttribute('aria-expanded', 'true');
					//
					modal_targets.forEach((target, index) => {
						target.setAttribute('aria-hidden', 'true');
					});
					modal_targets[index].setAttribute('aria-hidden', 'false');

					//
					modal_body.classList.add('open');
					lenis.stop();
				}
			});
		});

		modal_overlay.addEventListener("click", function () {
			modalClose();
		});

		modal_close.forEach((modalBtn2) => {
			modalBtn2.addEventListener("click", function () {
				modalClose();
				console.log(1);
			});
		});
	}





});
window.addEventListener("resize", function () {
	setScrollbarWidth();
});
